<!--
 * @Date: 2024-08-15 09:40:36
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-28 20:58:45
 * @FilePath: \zyt-mobile-frontend\src\views\me\module\menu\screen.vue
-->
<!--
 * 作者：zhang
 * 时间：2024/8/15 上午9:40
 * 功能：我的-放映员-菜单
 *
-->
<script>
import menuBtn from '../menuBtn.vue'
import { Toast } from 'vant'
import common from '@/utils/common'
import router from '@/router'
export default {
  name: 'Person',
  props: {
    userRoleCode: {
      type: String,
      default: '',
    },
  },
  components: {
    menuBtn,
  },
  data() {
    return {
      // 微信
    }
  },
  methods: {
    goPath(url) {
      console.log(url)
      console.log(common.hasPermission(url, this.userRoleCode))
      if (common.hasPermission(url, this.userRoleCode)) {
        router.push(url)
      } else {
        Toast({
          message:
            '系统监测您不是该功能的授权用户，如有问题请联系您所在的农村电影放映公司处理',
          duration: 1500,
        })
      }
      // this.$router.push(url);
    },
  }
}
</script>

<template>
  <div class="p-menu">
    <menu-btn
      title="放映员点播"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd9661db.png"
      @click="goPath('/screenDemand')"
    />
    <menu-btn
      title="放映确认"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd6af74d.png"
      @click="goPath('/screenPlans/confirm')"
    />
    <menuBtn
      title="放映任务"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fdb7933a.png"
      @click="goPath('/screenPlans')"
    />
    <menuBtn
      @click="goPath('/screenReports')"
      title="放映报告"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd4db202.png"
    />
    <menuBtn
      title="评价二维码"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd8a9099.png"
       @click="goPath('/qrEvaluate')"
    />
  </div>
</template>

<style scoped lang="less"></style>
