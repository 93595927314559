<!--
 * @Date: 2024-08-15 09:40:36
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-12 09:28:36
 * @FilePath: \zyt-mobile-frontend\src\views\me\module\menu\person.vue
-->
<!--
 * 作者：zhang
 * 时间：2024/8/15 上午9:40
 * 功能：
 *
-->

<template>
  <div class="p-menu">
    <menu-btn
      title="我要点播"
      @click="goPath('/onDemand')"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/08/67049fd9661db.png"
    />
    <!-- 点播记录 -->
    <menu-btn
      title="点播记录"
      @click="goPath('/playRecord')"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cd11d2205.png"
    />
    <!-- 放映评价 -->
    <menu-btn
      title="放映评价"
      @click="goPath('/sessionEvaluate')"
      icon="https://img.dev.zhijiangfilm.com/i/2024/10/12/6709cd12aac3a.png"
    />
    <!-- 退出登陆 -->
    <!-- <menu-btn
      title="退出登陆"
      icon="http://cdn.zhijiangfilm.com/2024/08/15/66bd60c112aaa.png"
    /> -->
  </div>
</template>

<script>
import menuBtn from '../menuBtn.vue'
import common from '@/utils/common'
import { Toast } from 'vant'
import router from '@/router'
export default {
  components: { menuBtn },
  name: 'Person',
  props: {
    userRoleCode:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      // 微信
    }
  },
  methods: {
    goPage(path, query) {
      this.$router.push({ path, query })
    },
    goPath(url) {
      console.log(url)
      console.log(common.hasPermission(url, this.userRoleCode))
      if (common.hasPermission(url, this.userRoleCode)) {
        router.push(url+'?type=personal')
      } else {
        Toast({
          message:
            '系统监测您不是该功能的授权用户，如有问题请联系您所在的农村电影放映公司处理',
          duration: 1500,
        })
      }
      // this.$router.push(url);
    },
  },
}
</script>
<style scoped lang="less"></style>
